class ManageIdeastorm {

  //Suggests a key based on either a client or project name
  static topform(component) {
      return [
        {
          id: 1,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_DETAILS")}`,
          errors: component.tab1Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputActivityTitle",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-activity-title',
                        model: component.$v.form.title,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                },
                {
                  components: [
                    {
                      name: "InputGenericTextArea",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-activity-summary',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_SUMMARY")}`,
                        model: component.$v.form.summary,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                },
                {
                  components: [
                    {
                      name: "InputGenericRichText",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-activity-introduction',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_INTRODUCTION")}`,
                        model: component.$v.form.introduction,
                        submissionStates: component.submissionStates,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_INTRODUCTION")}`,
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_STIMULI")}`,
              rows: [
                {
                  components: [
                    {
                      name: "IntegratedDropzone",
                      on: {
                        asset_reservation_created: (value) => { component.$refs["ideastorm-topform"].$emit('asset_reservation_created', value) },
                        asset_reservation_deleted: (value) => { component.$refs["ideastorm-topform"].$emit('asset_reservation_deleted', value) },
                        remove_project_asset: (value) => { component.$refs["ideastorm-topform"].$emit('remove_project_asset', value) },
                      },
                      properties: {
                        class: "col-xl-12 px-3 mb-0",
                        assetReservation: component.assetReservationData,
                        idx: 1,
                        projectAssets: component.activityInfo.stimulus_assets,
                        wholeWidth: true,
                        removable: true
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 2,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_SETTINGS")}`,
          errors: component.tab2Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-3 px-5 mb-0",
                        id: 'input-publish-toggle',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_PUBLISH")}`,
                        model: component.$v.form.published,
                        submissionStates: component.submissionStates
                      }
                    },
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-3 px-5 mb-0",
                        id: 'input-feature-toggle',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_FEATURED")}`,
                        model: component.$v.form.featured,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                },
                {
                  show: component.projectRoomSetupList.length > 0,
                  components: [
                    {
                      show: (component.isSequencedActivity == false &&
                            component.isDuplicateSequencedActivity == false &&
                            component.projectRoomSetupList.length > 0)
                            ? 
                            component.projectRoomSetupList[0].project_rooms.length > 0 : false,
                      name: "InputGenericDropdown",
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: 'input-room',
                        options: component.projectRoomSetupList.length > 0 ? component.projectRoomSetupList[0].project_rooms : null,
                        optionLabel: "title",
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_ROOM")}`,
                        model: component.$v.form.project_room_id,
                        errorMessage: `${component.$t("FORMS.ERROR.ACTIVITY_ROOM")}`,
                      }
                    }
                  ]
                }
              ]
            },
            {
              show: component.projectInfo.isTemplate == false,
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_DATES")}`,
              rows: [
                {
                  show: component.isSequencedActivity || component.isDuplicateSequencedActivity,
                  components: [
                    {
                      name: "InputGenericRadioOption",
                      properties: {
                        class: "col-xl-5 px-5 mb-0",
                        model: component.$v.sequence_option_id,
                        submissionStates: component.submissionStates,
                        id: "input-sequence-options",
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_SEQUENCE")}`,
                        error: `${component.$t("FORMS.ERROR.SEQUENCE_OPTION")}`, 
                        options: [
                          {
                            value: 1,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_SEQUENCED")}`,
                            tooltip: `${component.$t("ACTIVITY.TOOLTIPS.SEQUENCED")}`,
                          },
                          {
                            value: 2,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_SEQUENCED_AND_SCHEDULED")}`,
                            tooltip: `${component.$t("ACTIVITY.TOOLTIPS.SEQUENCED_AND_SCHEDULED")}`,
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  components: [
                    {
                      show: component.isSequencedActivity == false && component.isDuplicateSequencedActivity == false,
                      name: "InputGenericDatePicker",
                      on: {
                        date_updated: (value) => { component.$refs["ideastorm-topform"].$emit('start_date_updated', value) },
                      },
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: "activity-start-date",
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_START_DATE")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_START_DATE")}`,
                        type: "datetime",
                        notBefore: component.projectInfo.starts_on,
                        notAfter: component.projectInfo.ends_on,
                        model: component.$v.form.starts_on,
                        submissionStates: component.submissionStates
                      }
                    },
                    {
                      show: component.isScheduledActivity && (component.isSequencedActivity || component.isDuplicateSequencedActivity),
                      name: "InputGenericDatePicker",
                      on: {
                        date_updated: (value) => { component.$refs["ideastorm-topform"].$emit('start_date_updated', value) },
                      },
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: "activity-sequenced-start-date",
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_START_DATE")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_START_DATE")}`,
                        type: "datetime",
                        notBefore: component.projectInfo.starts_on,
                        notAfter: component.projectInfo.ends_on,
                        model: component.$v.form.starts_on,
                        submissionStates: component.submissionStates
                      }
                    },
                    {
                      show: component.isSequencedActivity == false && component.isDuplicateSequencedActivity == false,
                      name: "InputGenericDatePicker",
                      on: {
                        date_updated: (value) => { component.$refs["ideastorm-topform"].$emit('end_date_updated', value) },
                      },
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: "activity-end-date",
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_END_DATE")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_END_DATE")}`,
                        type: "datetime",
                        notBefore: component.projectInfo.starts_on,
                        notAfter: component.projectInfo.ends_on,
                        model: component.$v.form.ends_on,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_NOTIFICATIONS")}`,
              rows: [
                {
                  components: [
                    {
                      name: "InputActivityNotificationSettings",
                      on: {
                        initial_notification_period_changed: (value) => { component.$refs["ideastorm-topform"].$emit('initial_notification_period_changed', value) },
                        notification_repeat_option_changed: (value) => { component.$refs["ideastorm-topform"].$emit('notification_repeat_option_changed', value) },
                        repeat_notification_period_changed: (value) => { component.$refs["ideastorm-topform"].$emit('repeat_notification_period_changed', value) },
                      },
                      properties: {
                        class: "col-xl-12 pl-9",
                        id: 'input-activity-notification-settings',
                        submissionStates: component.submissionStates,
                        notificationToggle: component.$v.notification_toggle,
                        repeatNotificationToggle: component.$v.repeat_notification_toggle,
                        selectedNotificationPeriod: component.selected_notification_period,
                        selectedRepeatOption: component.selected_repeat_option,
                        selectedRepeatNotificationPeriod: component.selected_repeat_notification_period
                      }
                    },
                    {
                      name: "InputActivityVoteNotificationSettings",
                      on: {
                        initial_notification_period_changed: (value) => { component.$refs["ideastorm-topform"].$emit('initial_vote_notification_period_changed', value) },
                        notification_repeat_option_changed: (value) => { component.$refs["ideastorm-topform"].$emit('vote_notification_repeat_option_changed', value) },
                        repeat_notification_period_changed: (value) => { component.$refs["ideastorm-topform"].$emit('repeat_vote_notification_period_changed', value) },
                      },
                      properties: {
                        class: "col-xl-12 pl-9",
                        id: 'input-activity-vote-notification-settings',
                        submissionStates: component.submissionStates,
                        notificationToggle: component.$v.vote_notification_toggle,
                        repeatNotificationToggle: component.$v.vote_repeat_notification_toggle,
                        selectedNotificationPeriod: component.selected_vote_notification_period,
                        selectedRepeatOption: component.selected_vote_repeat_option,
                        selectedRepeatNotificationPeriod: component.selected_vote_repeat_notification_period
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_SOCIAL_SETTINGS")}`,
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericRadioOption",
                      properties: {
                        class: "col-xl-6 px-5",
                        id: 'input-activity-social-setting',
                        submissionStates: component.submissionStates,
                        model: component.$v.form.system_social_setting_id,
                        displayLabel: false,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_SOCIAL_SETTING")}`,
                        options: [
                          {
                            value: 1,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_OPEN")}`,
                            tooltip: `${component.$t("ACTIVITY.TOOLTIPS.OPEN")}`,
                          },
                          {
                            value: 2,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_IN_SEGMENT")}`,
                            tooltip: `${component.$t("ACTIVITY.TOOLTIPS.IN_SEGMENT")}`,
                          }
                        ]
                      }
                    },
                    {
                      name: "InputGenericRadioOption",
                      show: component.form.system_social_setting_id != 3,
                      properties: {
                        class: "col-xl-6 mb-0",
                        id: 'input-activity-bias',
                        submissionStates: component.submissionStates,
                        model: component.$v.form.system_social_bias_id,
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_BIAS")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_BIAS")}`,
                        options: [
                          {
                            value: 1,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_UNBIASED")}`,
                            tooltip: `${component.$t("ACTIVITY.TOOLTIPS.UNBIASED")}`,
                          },
                          {
                            value: 2,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_BIASED")}`,
                            tooltip: `${component.$t("ACTIVITY.TOOLTIPS.BIASED")}`,
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_VIDEO_SETTINGS")}`,
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericCheckboxGroup",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-supported-video-types',
                        submissionStates: component.submissionStates,
                        model: component.$v.selected_video_options,
                        options: [
                          {
                            value: 5,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_SUPPORT_VIDEO_UPLOAD")}`,
                          },
                          {
                            value: 6,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_SUPPORT_SCREEN_RECORD")}`,
                          },
                          {
                            value: 7,
                            text: `${component.$t("FORMS.INPUTS.ACTIVITY_SUPPORT_WEBCAM_RECORD")}`,
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 3,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_CATEGORIES")}`,
          errors: component.tab3Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputActivityCategories",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-activity-categories',
                        model: component.form.ideastorm_categories,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
  
export default ManageIdeastorm;